<template>
  <div>
    <b-card>
      <Table
        :loader="tableLoader"
        :items="items.results"
        :fields="fields"
        :sortBy="sortAcs"
        :sortAcs="sortAcs"
        :sortDesc="sortDesc"
        :getDocuments="getReport"
        :total="total"
        :type="typeDocument"/>
      <Paginate
        :current="items.current"
        :next="items.next"
        :prev="items.previous"
        :count="items.count"
        :changePage="getReport" />
    </b-card>
  </div>
</template>

<script>
import { queryString } from '@/mixins/main'

export default {
  name: 'ReportDistributionGroup',
  components: {
    Table: () => import('@/components/layouts/Table/Table.vue'),
    Paginate: () => import('@/components/atoms/Paginate.vue')
  },
  props: {
    search: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      fields: [
        { key: 'count',
          label: this.$i18n.t('itemsAmount')
        },
        { key: 'distribution_sum',
          label: this.$i18n.t('distribution')
        },
        { key: 'profit_sum',
          label: this.$i18n.t('profit')
        },
        { key: 'event',
          label: this.$i18n.t('go'),
          class: 'mw-0'
        }
      ],
      items: [],
      tableLoader: false,
      sortAcs: null,
      sortDesc: null,
      sortName: null,
      total: {},
      queryDocumentType: this.$route.query.type_document
    }
  },
  mounted () {
    this.getReport()
  },
  computed: {
    typeDocument () {
      if (this.$router.currentRoute.params['typeDoc'] === 'adv_training') {
        return 'reportDistributionAdvanceTraining'
      } else {
        return 'reportBO'
      }
    }
  },
  methods: {
    getReport (link = null) {
      this.tableLoader = true
      const typeDoc = this.$router.currentRoute.params['typeDoc']
      const urlId = this.$router.currentRoute.params['firstIdEntry']

      let url = ''
      if (link) {
        url = link
      } else {
        switch (typeDoc) {
          case 'sqc':
          case 'crewing_manager':
            url = `api/v1/reports/back_office/distribution/${typeDoc}/crewing_manager/?crewing=${urlId}&${this.search}`
            this.fields.unshift({ key: 'crewingManagerStatementFullName', label: this.$i18n.t('crewingManager') })
            break
          case 'eti':
            url = `api/v1/reports/back_office/distribution/${typeDoc}/courses/?institution=${urlId}&${this.search}`
            this.fields.unshift({ key: 'course', label: this.$i18n.t('course') })
            break
          case 'medical':
            url = `api/v1/reports/back_office/distribution/${typeDoc}/doctor/?medical=${urlId}&${this.search}`
            this.fields.unshift({ key: 'doctor', label: this.$i18n.t('doctor') })
            break
          case 'dpd':
            const type = this.queryDocumentType === 'passport' ? 'sailor_passport' : 'qual_doc'
            const query = queryString({
              ...this.$route.query,
              crewing: urlId
            })
            url = `api/v1/reports/back_office/distribution/${typeDoc}/manager/${type}/${query}`
            this.fields.unshift({ key: 'crewingManagerStatementFullName', label: this.$i18n.t('crewingManager') })
            break
          case 'portal':
          case 'sc':
            url = `api/v1/reports/back_office/distribution/${typeDoc}/crewing_manager/?branch_office=${urlId}&${this.search}`
            this.fields.unshift({ key: 'crewingManagerStatementFullName', label: this.$i18n.t('crewingManager') })
            break
          case 'adv_training':
            url = `api/v1/reports/back_office/distribution/${typeDoc}/sailor/?level=${urlId}&${this.search}`
            this.fields.splice(0, 1, { key: 'level_qualification', label: this.$i18n.t('qualification') })
            this.fields.unshift({ key: 'sailorFullName', label: this.$i18n.t('seafarer') })
            break
        }
      }
      this.$api.get(url).then(response => {
        let cloneResponce = { ...response.data.results }
        delete cloneResponce.data
        this.total = cloneResponce
        this.tableLoader = false
        if (response.code === 200) {
          response.data.results.data.map(item => {
            item.searchParams = this.search
            switch (typeDoc) {
              case 'sqc':
              case 'crewing_manager':
              case 'portal':
              case 'sc':
                item.link = `${urlId}/sailor/${item.manager.id}`
                break
              case 'dpd':
                item.link = `/report/distribution/usual/dpd/crewing/${this.queryDocumentType}/sailor/${item.manager.id}/${queryString(this.$route.query)}`
                break
              case 'eti':
                item.link = `${urlId}/sailor/${item.course.id}`
                break
              case 'medical':
                item.link = `${urlId}/sailor/${item.doctor ? item.doctor.id : 0}`
                break
            }
          })
          this.items = response.data
          this.items.results = response.data.results.data
        }
      })
    }
  }
}
</script>
